.info__title {
    overflow: hidden;
    text-overflow: ellipsis;
}
.info__about{
    height: 100%;
    padding-top: 2rem;
}

.info__features {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    width: min-content;
    column-gap: 4rem;
    row-gap: 0.5rem;
}

.info__description {
    padding-right: 4rem;
    padding-top: 2rem;
    white-space: pre-wrap;
}

.info__description p {
    font-size: 1rem;
}

@media screen and (max-width: 991px){
    .info__description {
        padding-right: 0;
    }

    .info__container {
        margin-top: 3rem;
    }
}

.info__possibilities{
    font-size: 0.8rem;
    display: inline-block;
}

.info__features .features__title {
    grid-column: 1;
    white-space: nowrap;
    font-size: 1rem;
}

.info__features .features__value {
    grid-column: 2;
    white-space: nowrap;
    font-size: 1rem;
    opacity: 0.7;
}

.full__screen__mode {
    position: fixed;
    padding-top: 5rem;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255);
    z-index: 3;
}
.full__screen__mode iframe {
    width: 100%;
    height: 100%;
}
.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    text-align: center;
}

.token__container {
    display: flex;
    justify-content: flex-end;
    align-items:start;
    padding-top: 1rem;
}

.info__container {
    width: 50%;
}

@media screen and (max-width: 991px) {
    .info__container {
        margin-top: 3rem;
    }
}

.media__container {
    object-fit: contain;


    fill: var(--f40);

    position: fixed;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    padding: 0 100px 80px;
    height: calc(100% - 150px);
    width: calc(65vw - 200px);
    min-width: 0;
    top: 50%;
    left: 0;
    transform: translateY(calc(-50% + 50px));
}

.bid__container {
    display: flex;
    height: 100%;

    margin-top: 1rem;
}

.bid__input {
    width: 60%;

    border: 1px solid var(--f40);
    border-radius: 0.25rem;
}

.bid__submit {
    width: 40%;
    margin-left: 0.5rem;
}

.swap__submit {
    width: 100%;
    font-size: larger;
    font-weight: bold;
}

.auction__container {
    margin-top: 2rem;
}

.links {
    margin-top: 1rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    border-radius: 0.25rem;

    .link {
        padding: 0.5rem;

        width: 50%;
        text-align: center;

        position: relative;
    }    
}

.editions__info {
    display: block;
    width: 100%;
    flex-wrap: wrap;
}

.editions__info__box {

    padding: 1rem 0 1rem 0;

    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid var(--f40); 
}
.editions__ending__info__box {

    padding: 1rem 0 0 0;

    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid var(--f40); 
}

.editions__info__box h2 {
    font-size: 1.2rem;
    margin-top: 0.6rem;
}

.editions__availability__box {
    border-top: 1px solid var(--f40);
    border-radius: 0.25rem;
    width: 100%;
    padding: 0.5rem;
}

.royalties__info__box{
    padding: 1rem 0 1rem 0;

    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid var(--f40); 
    border-bottom: 1px solid var(--f40); 
}
.auction__price {
    border: 1px solid var(--f40);
    border-radius: 0.25rem;

    padding: 0.5rem;

    display: flex;
    flex-direction: column;
}

.creator {
    padding: 0;

    font-size: larger;
    margin-bottom: 2rem;
}

.sold_out {
    font-size: larger;
    margin-top: 2rem;
    text-transform: uppercase;
    font-family: 'OCR A Extended';
}

.auction__history {
    margin-top: 1rem;

    display: flex;
    flex-direction: column;

    border: 1px solid var(--f40);
    border-radius: 0.25rem;
}

.history__item {
    padding: 1rem;

    display: flex;
    flex-direction: column;
    align-content: flex-start;

    border-top: 1px solid var(--f40);

    &:first-child {
        border-top: none;        
    }

    color: var(--f40);
}

.history__item__inner {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    width: fit-content;
    margin: 0 auto;
}

.timestamp {
    padding: 0.25rem 0;
}

.message {
    text-align: center;

    width: 100%;

    margin-top: 2rem;
}

.top__bidder {
    background-color: var(--f100);
    color: var(--b100);

    padding: 0.5rem 0;

    text-align: center;
}

.link__container {
    width: 100%;
    height: 100%;
}

.link__label {
    width: 100%;
}

.additionalinfo {
    
    margin-bottom: 3rem;

    a {
        text-decoration: underline;
    }
    
    h3 {
        font-size: 1.3rem !important;
    }
    
    p {
        font-size: 1rem !important;
        margin-bottom: 1rem;
    }

    li {
        list-style: circle
    }
}

.social__share {
    width: 100%;
    border: 1px solid var(--f40);
    border-radius: 0.25rem;
    flex-wrap: wrap;
    margin-top: 1rem;
}
.social__share__box {
    &:nth-child(even) {
        border-left: 1px solid var(--f40);
    }

    padding: 0.5rem;
    text-align: center;

    display: flex;
    flex-direction: column;
}
.social__share__text{
    vertical-align: middle;
    display: inline-block;

}
@media (max-width: 1280px) {
    .token__container {
        flex-direction: column;
        justify-content: flex-end;
        align-items:end;
    }

    .media__container {
        position: relative;
        padding: 0;
        width: 100%;
        transform: none;
    }

    .info__container {
        width: 100%;
    }
}

.media__container.iframe {
    padding-bottom: 0;
    width: 60%;
    position: relative;
    transform: none;
    padding: 0;
    margin: 0 auto;
    display: flex;
}

/** AB specific **/
.minted__container {
    margin-top: 8rem;
}

.minted_preview__container {
    display: flex;
    margin-top: 3rem;
    column-gap: 4%;
    row-gap: 2rem;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.icon__style{
    font-size: 3rem;
}

.availability__info__box{
    &:nth-child(even) {
        border-left: 1px solid var(--f40);
    }

    padding-bottom: 1rem;


    display: flex;
}

.event__cover{
    justify-content: center;
}

.charity__info__description {
    padding: 1rem 0 1rem 0.5rem;
    white-space: pre-wrap;
}
.charity__markdown__description{
}

.notes__button {
    content: '\02794'; /* Unicode character for "plus" sign (+) */
    min-width: 25rem;
    font-size: larger;
    display: flex;
  }

.notes__info{
    padding-bottom: 2rem;
}
.notes__info__text {
    padding: 0.5rem 0.5rem 1rem 0.5rem;
    background-color: #f1f1f100;
    color: white;
    font-size: 1rem;
    white-space: pre-wrap;
    display: inline-flex;
}
.notes__icon__style{
    display: inline-flex;
    font-size: 1rem;
    justify-content: baseline;
}
.additional__info__text{
    text-align: left;
    padding: 1rem 0 1rem 0;

}
.additional__info__headline{
    font-size: 1rem;
    padding-bottom: 0.3rem;
}

.da_label {
    font-size: 0.9rem;
    opacity: 0.8;
    padding-top: 1rem;
}

.countdown{
    font-size: larger;
    text-transform: uppercase;
    font-family: 'OCR A Extended';
    padding-left: 0.5rem;
}

.banner {
    width: 80%;
    margin: 0 auto;
}

@media screen and (max-width: 991px) {
    .banner {
        width: 90%;
    }
}